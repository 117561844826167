import React from 'react';

// Components
import { FilterBar } from 'components/FilterBar';

// Templates
import { BasicTemplate, HallOfFameCards } from 'templates';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Styles
import { inner } from 'styles';

// GraphQL
import { useAthletesHOFQuery } from 'graphql';

// Types
import { HOF } from 'src/types';
export interface Props {
  data: {
    athletes: HOF;
  };
  path: string;
}

const FILTERS = [
  { title: 'Last Name', gqlKey: 'last_name' },
  { title: 'School', gqlKey: 'school' },
  { title: 'Induction Year', gqlKey: 'induction_year' },
  { title: 'Class Year', gqlKey: 'class_year' },
  { title: 'Gender', gqlKey: 'gender' },
];

const title = 'Athletes Hall of Fame';

const AthletesHOFPageTemplate = (props: Props) => {
  const { athletes } = useAthletesHOFQuery();

  return (
    <SEOContent>
      <PageLayout>
        <BasicTemplate templateTitle={title}>
          <div css={inner}>
            <FilterBar
              data={athletes}
              filters={FILTERS}
              path={props.path}
              render={tableData => (
                <HallOfFameCards data={tableData} linkPrefix="athletes" />
              )}
            />
          </div>
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export default AthletesHOFPageTemplate;
